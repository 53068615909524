
const errorResponse = (response: any): object => {
	let newErrorFormat = {};

	if (typeof response === "object") {
		
		scrollToError(response);
		for (const item in response) {
			newErrorFormat = {
				...newErrorFormat,
				[item]: typeof response[item] === "string" ? response[item] : response[item][0],
			};
		}

		return newErrorFormat;
	}


	return { message: response };
};

const scrollToError = (response: object):void => {

	try {
		const errors = Object.keys(response);

		const element = <HTMLElement>document.getElementById(errors[0])
        
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                inline: "start",
            });
        }
	} catch (error) {
		console.log(error);
	}

}

export { errorResponse };
